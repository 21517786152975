<template>
  <Layout>
    <div class="parent">
      <div class="timeline">
        <div class="container">
          <div class="content">
            <h2>2014</h2>
            <p>Farmers Market</p>
          </div>
        </div>
        <div class="container">
          <div class="content">
            <h2>2017</h2>
            <p>Open NEV</p>
          </div>
        </div>
        <div class="container">
          <div class="content">
            <h2>2020</h2>
            <p>Open City</p>
          </div>
        </div>
      </div>
    </div>
    <p></p>
  </Layout>
</template>

<script>
import Layout from "./Layout.vue";

export default {
  data() {
    return {};
  },
  components: {
    Layout,
  },
};
</script>
<style scoped lang="scss">
.parent {
  // margin-bottom: 2rem;
  background-color:var(--text-colour);
  min-height: 60vh;
  max-width: 70vw;
  margin: auto;
  transform: translateY(25%);
  box-shadow: 0px 0px 10px 0px rgba(119, 179, 232, 1);
  border-radius: 1rem;
  .timeline {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 60vh;
    overflow-x: auto;
  }
  .timeline::after {
    content: "";
    position: fixed;
    height: 0.5rem;
    background-color: #337ab7;
    left: 0;
    right: 0;
    top: 90%;
  }
  .container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    min-width: 50%;
    bottom: 25%;
    min-height: 0;
  }
  .container::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: var(--text-colour);
    border: 4px solid #337ab7;
    bottom: calc(10% - 1rem);
    border-radius: 50%;
    z-index: 1;
  }
  @media only screen and (orientation: portrait) {
    .container {
      min-width: 100%;
    }
  }
}
</style>
