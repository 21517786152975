<template>
  <Layout>
    <div class="parent">
      <h2>Spread the <span class="highlight">Love</span> with bagels</h2>
      <h3>48 hours notice required as our bagels numbers are small batch & rolled to order, we rise over 24 hours to ensure they are full of flavour.</h3>
      <div class="catering-info">
        <section>
          <div class="catering-img">
            <img loading="lazy" src="/rosemary.jpg" alt="A selection of rosemary bagels fresh out of the oven" />
          </div>
          <p>Freshly baked Regular sized bagels just order ahead and collect on the day. Great for securing a larger number in the flavours you want.</p>
        </section>

        <section>
          <div class="catering-img">
            <img loading="lazy" src="/catering.png" alt="A catering selection of homemade bagels and bagel spreads" />
          </div>
          <p>Bagel Box options - Mini Canape sized perfect for a morning tea or grazing table. Or our Big Boxes of regular sized bagels self serve style - an awesome lunch feast or work shout treat.</p>
        </section>

        <section>
          <div class="catering-img">
            <img loading="lazy" src="/spreads.jpg" alt="A popular home made bagel spread called vulcan" />
          </div>
          <p>Our best - selling Beam Me Up spreads available for purchase - a delicious addition to a mini box or our freshly baked bagels.</p>
        </section>
      </div>


      <div class="buttons">
        <a href="https://bagels-catering.web.beamy.nz" class="button">
          <h1>Order Here</h1>
        </a>
      </div>

      <p>
        *Pick up time, preferred store & payment all required at ordering.<br />
        We do not take email orders but stores can be emailed directly if you have questions, city@beammeupbagels.co.nz or nev@beammeupbagels.co.nz for the respective store.
      </p>
    </div>
  </Layout>
</template>
<script>
import Layout from "./Layout.vue";
export default {
  data() {
    return {};
  },
  components: {
    Layout,
  },
};
</script>
<style scoped lang="scss">
.parent {
    // .buttons {
    //     // a {
    //     // }
    // }
  margin: auto;

  h2 {
    color: var(--text-colour);
    font-size: 32px;
    .highlight {
      color: var(--action-colour);
    }
  }

  h3 {
    color: var(--text-colour);
    padding: 2rem 5rem;
  }

  p {
    color: var(--text-colour);
  }

  .catering-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1rem 1rem 1rem 1rem;

    section {
        p {
            margin: 1vw 3vw 0 3vw;
        }
      margin: 0rem 1rem;
      .catering-img {
          // object-fit: contain;
        img {
          border-radius: 0.5rem;
          object-fit: cover;
          width: 25vw;
          height: 20rem;
        }
      }
    }

  }
}

@media only screen and (orientation: portrait) {
  .parent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
    h3{
      padding: 0 1rem;
    }
    p{
      margin-top: 0rem;
    }
    

    .catering-info{
      grid-template-columns: 1fr;
      margin:0;
      section {
          .catering-img {
              img {
                  width: 80vw;
              }
          }
      }
    }
  }
}
</style>
