<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
    // font-family: zona;
  background-color: var(--secondary-colour);
}

html, body {
  margin: 0px;
  padding: 0px;
  // font-family: 'Lato';
  overscroll-behavior: none;
  input {
    font-family: 'Lato';
    font-size: 1em;
  }

}
</style>
