<template>
  <Layout>
    <div class="parent">
      <div class="child">
        <div class="locations">
          <Location
            title="NEV"
            image="/NEV2.jpg"
            alt="North East Valley Shop"
            address="9 North Road"
            suburb="North East Valley"
            city="Dunedin"
            email="nev@beammeupbagels.co.nz"
            lat="-45.85460"
            long="170.51916"
            website="https://bagels-nev.web.beamy.nz"
          />
          <Location
            title="CITY"
            image="/city.png"
            alt="City center shop"
            address="102 Great King Street"
            suburb="Central City"
            city="Dunedin"
            email="city@beammeupbagels.co.nz"
            lat="-45.87154"
            long="170.50644"
            website="https://bagels-city.web.beamy.nz"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Location from "./Location.vue";
import Layout from "./Layout.vue";

export default {
  data() {
    return {};
  },
  components: {
    Location,
    Layout,
  },
};
</script>

<style scoped lang="scss">
.parent {
  .child {
    h1 {
      font-size: 3rem;
      text-align: center;
      color: #000;
    }
    h2 {
      font-size: 2rem;
      text-align: center;
    }
    h3 {
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 2rem;
    }
    .locations {
      padding: 2rem 0rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      background-color: var(--secondary-colour)
    }
  }
}

@media only screen and (orientation: portrait) {
  .parent {
    .child {
      .locations {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 2rem;
      }
    }
  }
}
</style>
