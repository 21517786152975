<template>
<div class="footer">
  <div class="footer-info">
    <div class="hours">
      <h2>Hours</h2>
        <p>Daily - 8:30am 2:30pm</p>
        <p>Public Hol. - 9:30am 2:30pm</p>
    </div>
    <div class="online">
      <h2>Socials</h2>
      <div class="social-icons">
        <a href="https://www.facebook.com/beammeupbagels" target="_blank"><font-awesome-icon :icon="['fab','facebook' ]"/></a>
        <a href="https://www.instagram.com/beammeupbagels" target="_blank"><font-awesome-icon :icon="['fab','instagram' ]"/></a>
      </div>
    </div>
  </div>
  <span>&copy; {{ this.Year.getFullYear() }} Beam Me Up Bagels LTD</span>
  
</div>

</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'Footer',
  data() {
        return {
            Year:new Date
        }
      },
  components: {
    FontAwesomeIcon
  },
  methods:{

  }
}

</script>

<style scoped lang="scss">
.footer {
  background-color: #1c1f22;
  color: white;
  position: relative;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:10px;

  span{
    color: var(--action-colour);
  }
  .footer-info {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding: 0rem 8rem;

    .hours{
      text-align: left;
    }
    .online{
        text-align: right;
      .links{
        
        
        a{
          // background-color: var(--text-colour);
          color: var(--text-colour);
          text-decoration: none;
          svg {
              color: var(--action-colour);
          }
        }
      }      
    }

    h2{
      font-size: 2rem;
      font-weight: 600;
      margin: 0.5rem 0rem;
    }
  }

  .social-icons{
    font-size: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    a{
    color: var(--text-colour);
      
      &:hover{
        color: var(--action-colour);
      }
    }
  }
}
@media only screen and (orientation: portrait){

  .footer{
    .footer-info {
      flex-direction: column;
      padding: 0rem 2rem;

      .online{
        text-align: left;
      }
    }

  }
  
}
  


</style>
