import { createApp } from "vue";
import App from "./App.vue";
import "./index.scss";
import "@/assets/fonts/fonts.scss";

import { createRouter, createWebHistory } from "vue-router";
import Home from "./components/Home.vue";
import OrderOnline from "./components/OrderOnline.vue";
import OurLocations from "./components/OurLocations.vue";
import Catering from "./components/Catering.vue";
import About from "./components/About.vue";
import Vouchers from "./components/Vouchers.vue";

import VueGoogleMaps from "@fawmi/vue-google-maps";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faEdit,
  faPlus,
  faSave,
  faTrash,
  faBars,
  faSort,
  faMinusCircle,
  faPlusCircle,
  faEnvelope,
  faCaretDown,
  faShoppingCart,
  faShop,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
library.add(faPhone, faEdit, faPlus, faSave, faTrash, faBars, faSort, faMinusCircle, faPlusCircle, faEnvelope, faCaretDown, faFacebook, faInstagram, faShoppingCart, faShop, faShoppingBasket);

const router = new createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Home, meta: { title: "Home" } },
    { path: "/order", name: "Order Online", component: OrderOnline, meta: { title: "Order Online" } },
    { path: "/locations", name: "Locations", component: OurLocations, meta: { title: "Locations" } },
    { path: "/catering", name: "Catering", component: Catering, meta: { title: "Catering" } },
    { path: "/about", name: "About", component: About, meta: { title: "About Us" } },
    { path: "/vouchers", name: "Vouchers", component: Vouchers, meta: { title: "Vouchers" } },
  ],
});

router.beforeEach(async (to, from, next) => {
  document.title = "BMU - " + to.meta.title;
  return next();
});

const app = createApp(App);
app
  .use(router)
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyCSNpR2vHiqOErvlXST3W7O3WFqRRW_X1s",
    },
  })
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
