<template>
  <Layout>
    <div class="parent">
      <div class="content">
        <div class="slide-container">
          <div
            v-for="(slide, index) in slides"
            :key="index"
            class="mySlides fade"
            :style="{ display: slide.display }"
          >
            <img :src="slide.src" :alt="slide.alt" />
          </div>
        </div>
        <div class="hero">
          <div class="order-buttons">
            <div class="buttons">
              <a href="https://bagels-city.web.beamy.nz" class="button">
                <h1>Order from <span class="test">CITY</span></h1>
              </a>
            </div>

            <div class="buttons">
              <a href="https://bagels-nev.web.beamy.nz" class="button">
                <h1>Order from <span class="test">NEV</span></h1>
              </a>
            </div>
            <div class="buttons">
              <a href="/catering" class="button">
                <h1>Bagel <span class="test">Pre-Order</span></h1>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "./Layout.vue";

// import NavBar from './Navbar.vue'

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      slideIndex: 0,
      dots: [{ active: true }, { active: false }, { active: false }],
      slides: [
        {
          src: process.env.BASE_URL + "nev.jpg",
          alt: "North East Valley Shop",
          display: "none",
        },
        {
          src: process.env.BASE_URL + "front-bagels2.png",
          alt: "Selection of popular Bagels",
          display: "none",
        },
        {
          src: process.env.BASE_URL + "bagel-make.jpg",
          alt: "A Bagel being made",
          display: "none",
        },
      ],
    };
  },
  components: {
    Layout,
  },
  mounted() {
    // this.dots = this.$el.querySelectorAll(".dot");
    this.showSlides();
  },
  methods: {
    showSlides() {
      for (let i = 0; i < this.slides.length; i++) {
        this.slides[i].display = "none";
      }
      this.slideIndex++;
      if (this.slideIndex > this.slides.length) {
        this.slideIndex = 1;
      }
      for (let i = 0; i < this.dots.length; i++) {
        this.dots[i].active = false;
      }
      this.slides[this.slideIndex - 1].display = "block";
      // this.dots[this.slideIndex - 1].active = true;
      setTimeout(this.showSlides, 4000);
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  .content {
    //   background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .order-buttons {
      display: flex;
      position: relative;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      width: 85vw;
      // margin-top: 10rem;
      color: var(--text-colour) fff;
      text-align: center;
      font-size: 2rem;
      h1 {
        font-weight: 400;
        .test {
          font-weight: 600;
          //color: var(--action-colour);
        }
      }
    }

    /* Slideshow container */
    .slide-container {
      z-index: 1;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      margin-top: 1rem;

      .mySlides {
        display: none;
      }

      img {
        border-radius: 1rem;
        margin: 0rem 0rem 1rem 0rem;
        width: 80vw;
        height: 70vh;
        object-fit: cover;
        // border: 0.3rem solid var(--primary-colour);
      }

      .dot {
        height: 15px;
        width: 15px;
        margin: 0 2px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
      }

      .active {
        background-color: #717171;
      }

      .fade {
        animation-name: slidein;
        animation-duration: 3s;
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .parent {
    .content {
      .order-buttons {
        flex-direction: column;
      }

      /* Slideshow container */
      .slide-container {
        // .mySlides {
        // }

        img {
          width: 95vw;
        }

        .active {
          background-color: #717171;
        }
      }
    }
  }
}
</style>
