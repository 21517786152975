<template>
  <div class="navbar">
    <div class="nav-title">
      <router-link to="/">
        <h1>BEAM ME UP <span class="title">BAGELS</span></h1>
      </router-link>
    </div>
    <nav class="nav-menu">
      <router-link to="/">Home</router-link>
      <router-link to="/locations">Stores</router-link>
      <router-link to="/catering">Pre-Order</router-link>
      <router-link to="/vouchers">Vouchers</router-link>
    </nav>

    <div class="hamburger" @click="navbarOpen = !navbarOpen">
      <font-awesome-icon class="ham-icon" icon="bars" />
      <nav :class="navbarOpen ? 'ham-nav-menu' : 'ham-nav-menu-hide'">
        <router-link id="nav-option" to="/">Home</router-link>
        <router-link id="nav-option" to="/locations">Stores</router-link>
        <router-link id="nav-option" to="/catering">Pre-Order</router-link>
        <router-link id="nav-option" to="/vouchers">Vouchers</router-link>
      </nav>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      navbarOpen: false,
      onlineDropper: false,
    };
  },
  methods: {},
  components: { FontAwesomeIcon },
};
</script>

<style scoped lang="scss">
.navbar {
  display: flex;
  height: 6rem;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: var(--primary-colour);
  padding: 0rem 0rem;
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
  z-index: 2;
  .nav-title {
    a {
      display: flex;
      align-items: center;
      color: var(--text-colour);
      h1 {
        margin: 0rem;
        margin-left: 2rem;
        font-size: 3.5rem;
        font-weight: 500;
        font-family: aileron;
        color: var(--text-colour);
        .title {
          color: var(--action-colour);
        }
      }
    }
  }

  .nav-menu {
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .menu-dropdown {
      .dropdown-hide {
        display: none;
      }
      .dropdown-show {
        display: block;
        position: absolute;
        background-color: var(--text-colour);
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        a {
          float: none;
          color: black;
          padding: 12px 0px;
          text-decoration: none;
          display: block;
          text-align: left;
          margin: 0;
          padding: 1rem;
          &:hover {
            background-color: #ddd;
          }
        }
      }
    }
    a {
      text-decoration: none;
      color: var(--text-colour);
      font-size: 1.5rem;
      font-weight: 50;
      margin: 0 1rem;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: var(--action-colour);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }

      &:hover::after {
        color: var(--action-colour);
        text-decoration: none;
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
  .hamburger {
    display: none;
  }
}

@media only screen and (orientation: portrait) {
  .navbar {
    padding: 0.5rem 0rem;

    .nav-title {
      a {
        margin-left: 2rem;
        h1 {
          font-size: 2.25rem;
          margin: 0rem;
        }
      }
    }

    .hamburger {
      display: block;
      margin-right: 2rem;
      color: var(--text-colour);
      position: relative;
      z-index: 99;

      .ham-icon {
        font-size: 2rem;
        position: relative;
        z-index: 9;
      }

      .ham-nav-menu-hide {
        display: none;
      }
      .ham-nav-menu {
        position: absolute;
        z-index: 99999999;
        top: 3rem;
        right: 0rem;
        background-color: var(--text-colour);
        padding: 1rem 0rem;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
        a {
          text-decoration: none;
          color: #000;
          font-size: 1.2rem;
          font-weight: 500;
          margin: 0 1rem;
          display: block;
          padding: 0.5rem 1rem;
          &:hover {
            color: #000;
            text-decoration: underline;
          }
        }
      }
    }
    .nav-menu {
      display: none;
    }
  }
}

// @media only screen and (max-width: 1100px) {
//   .navbar{
//     .nav-menu{
//       display: none;
//     }

//     .hamburger{
//       display: block;
//       .ham-nav-menu-hide{
//         display: none;
//       }

//     }
//   }

// }
</style>
