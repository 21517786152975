<template>
  <div class="outer-content">
    <div class="background"></div>
    <NavBar />
    <div id="content"><slot /></div>
    <Footer />
  </div>
</template>

<script>
import NavBar from "./Navbar.vue";
import Footer from "./Footer.vue";
export default {
  name: "App",
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style lang="scss">
.outer-content {
  display: flex;
  flex-direction: column;


  #content {
    // margin-bottom: 2rem;
    min-height: 75vh;
    overflow: hidden;
  }
}
</style>
