<template>
  <div class="parent">
    <div>
      <NavBar />
    </div>
    <div class="content">
      <iframe src="https://pos.beamy.nz/#/store/bagels-city"> </iframe>
    </div>
  </div>
</template>
<script>
import NavBar from "./Navbar.vue";

export default {
  data() {
    return {};
  },
  components: {
    NavBar,
  },
};
</script>

<style scoped lang="scss">
.parent {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;

    iframe {
      width: 75%;
      height: 80vh;
    }
  }
}

@media only screen and (orientation: portrait) {
}
</style>
