<template>
  <div class="flip-card" :class="{ flipped: isFlipped }">
    <div class="flip-card-inner">
      <div class="flip-card-front location" @click="flipCard">
        <div class="image-parent">
          <img :src="image" />
          <div class="buttons">
            <div :href="website" class="button">
              <h1>
                View <span class="highlight">{{ title }}</span> Store
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="flip-card-back location">
        <div class="info">
          <GMapMap :center="{ lat: parseFloat(lat), lng: parseFloat(long) }" :zoom="18" map-type-id="terrain" class="map"> </GMapMap>
          <div class="side-by-side">
            <div class="address" @click="flipCard">
              <p>{{ address }}</p>
              <p>{{ suburb }}</p>
              <p>{{ city }}</p>
            </div>

            <div class="buttons">
              <a :href="website" class="button">
                <h1>
                  Order from <span class="highlight">{{ title }}</span>
                </h1>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
// import { LMap, LTileLayer } from "@vue-leaflet/vue-leaflet";

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      zoom: 18,
      isFlipped: false,
    };
  },
  methods: {
    flipCard() {
      this.isFlipped = !this.isFlipped;
    },
  },
  props: {
    title: String,
    image: String,
    address: String,
    suburb: String,
    city: String,
    email: String,
    lat: String,
    long: String,
    website: String,
  },
  components: {},
};
</script>

<style scoped lang="scss">
.flip-card {
  background-color: transparent;
  width: 45vw;

  .flip-card-inner {
    transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-style: preserve-3d;
    height: 100%;

    .flip-card-back {
      transform: rotateY(180deg);
      color: var(--text-colour);
      border-radius: 0.5rem;
      background: var(--primary-colour);
      .button{
        width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .info {
        // margin: 1rem 2rem 0rem 2rem;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .side-by-side {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .map {
          text-align: left;
          height: 45vh;
          width: 100%;
          border-radius: 0.5rem 0.5rem 0 0;
          overflow: hidden;
        }
        .address {
           padding-top: 1rem;
          p {
            margin: 0.5rem 0rem;
            min-width: 25vw;
          }
        }
      }
      .email {
        display: block;
        margin: 1rem 0;
        color: var(--text-colour);
        text-align: center;
        width: 100%;
      }
    }

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      width: 100%;
    }
  }

  /* Add flipped class for the flip animation */
  &.flipped .flip-card-inner {
    transform: rotateY(180deg);
  }
}

.location {
  h2 {
    color: var(--action-colour);
  }

  .image-parent {
    position: relative;

    img {
      object-fit: cover;
      width: 45vw;
      height: 60vh;
      border-radius: 0.5rem;
    }

    .buttons {
        position: absolute;
        .button {
            width: 15vw;
        }
    }
  }
}

@media only screen and (orientation: portrait) {
  .flip-card {
    height:70vh;
    width: 90vw;

    .flip-card-inner {
      .flip-card-front,
      .flip-card-back {
        width: 100%; // Adjusted width for both front and back
      }

      .flip-card-back {
        .info {
          .side-by-side {
            flex-direction: column;
          }
        }
      }
    }
  }

  .location {
    width: 90%;

    .image-parent {
      img {
        width: 100%;
        height: 68.5vh;
      }
    }
  }
}

</style>
