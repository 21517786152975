<template>
  <Layout>
<iframe src="https://voucher.beamy.nz/site/bagels-vouchers" />
</Layout>
</template>
<script>
import Layout from "./Layout.vue";
export default {
  data() {
    return {};
  },
  components: {
    Layout,
  },
};
</script>
<style scoped>
  iframe {
  height: 600px;
  width: 400px;
  }
</style>
